



























































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import TextInput from '@/elements/TextInput.vue'

import Modal from '@/components/Modal.vue'

import * as authStore from '@/store/authorization'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import communicationsClient from '@/clients/communicationsClient'
import partnersClient from '@/clients/partnersClient'
import { TwilioApplicationRequest } from '@/GeneratedTypes/Communication/TwilioApplicationRequest'

@Component({
  components: {
    TextInput,
    VeeValidateForm,
    Modal,
  },
})
export default class SmsApplicationModal extends Vue {
  @Getter(authStore.getterNames.firstAccountNumber, { namespace: authStore.namespace })
  private accountNumber!: string

  @Getter(authStore.getterNames.accountName, { namespace: authStore.namespace })
  private accountName!: string

  private loading = false
  private saving = false
  private tollFreeAppRequired = false
  private tollFreeAppShowClosed = true
  private serviceIDHelpIsVisible = false

  protected appRequest = {} as TwilioApplicationRequest

  private step = 1

  nextClicked() {
    this.step = 2
  }

  cancel() {
    this.tollFreeAppRequired = false
    this.tollFreeAppShowClosed = true
  }

  private async submit() {
    try {
      this.saving = true
      await communicationsClient.createTwilioTollFreeApplication(this.appRequest)
      this.step = 3
    } catch (error) {
      throw error
    } finally {
      this.saving = false
    }
  }

  private async isTollFreeAppPending() {
    var isAllowed = await communicationsClient.canSubmitTollFreeApp(this.accountNumber)

    if (isAllowed) {
      this.tollFreeAppRequired = true
    }

    this.tollFreeAppShowClosed = !this.tollFreeAppRequired
  }

  private async getDefaultTollFreeApplication() {
    if (!this.tollFreeAppRequired) return //only load data if modal is being displayed

    try {
      const partnerInfo = await partnersClient.getPartnerInfo(this.accountNumber)

      this.appRequest.accountNumber = this.accountNumber
      this.appRequest.partnerName = this.accountName
      this.appRequest.businessName = this.accountName.substring(0, this.accountName.indexOf('-'))

      if (partnerInfo && partnerInfo.primaryContactIdName) {
        this.appRequest.contactFirstName = partnerInfo.primaryContactIdName.substring(
          0,
          partnerInfo.primaryContactIdName?.lastIndexOf(' ')
        )
        this.appRequest.contactLastName = partnerInfo.primaryContactIdName.substring(
          partnerInfo.primaryContactIdName?.lastIndexOf(' ') + 1,
          partnerInfo.primaryContactIdName?.length
        )
      } else {
        this.appRequest.contactFirstName = ''
        this.appRequest.contactLastName = ''
      }

      this.appRequest.contactEmail = partnerInfo ? partnerInfo.primaryContactEmailAddress : ''
      this.appRequest.contactMobilePhone = partnerInfo ? partnerInfo.primaryContactPhoneNumber : ''
      this.appRequest.serviceID = ''
      this.appRequest.address = partnerInfo ? partnerInfo.address1_Line1 : ''
      this.appRequest.address2 = partnerInfo ? partnerInfo.address1_Line2 : ''
      this.appRequest.city = partnerInfo ? partnerInfo.address1_City : ''
      this.appRequest.stateProvince = partnerInfo ? partnerInfo.address1_StateOrProvince : ''
      this.appRequest.postalCode = partnerInfo ? partnerInfo.address1_PostalCode : ''
      this.appRequest.website = partnerInfo ? partnerInfo.webSiteUrl : ''
      this.appRequest.estimatedVolume = 10000
    } catch (error) {
      throw error
    }
  }

  public async mounted() {
    try {
      this.loading = true
      await this.isTollFreeAppPending()
      await this.getDefaultTollFreeApplication()
    } catch (error) {
      throw error
    } finally {
      this.loading = false
    }
  }
}
